exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-blog-articles-list-tsx": () => import("./../../../src/templates/BlogArticlesList.tsx" /* webpackChunkName: "component---src-templates-blog-articles-list-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-calculate-page-tsx": () => import("./../../../src/templates/CalculatePage.tsx" /* webpackChunkName: "component---src-templates-calculate-page-tsx" */),
  "component---src-templates-case-studies-site-tsx": () => import("./../../../src/templates/CaseStudiesSite.tsx" /* webpackChunkName: "component---src-templates-case-studies-site-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../../src/templates/ContactPage.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-fashion-site-tsx": () => import("./../../../src/templates/FashionSite.tsx" /* webpackChunkName: "component---src-templates-fashion-site-tsx" */),
  "component---src-templates-how-it-works-site-tsx": () => import("./../../../src/templates/HowItWorksSite.tsx" /* webpackChunkName: "component---src-templates-how-it-works-site-tsx" */),
  "component---src-templates-industries-site-tsx": () => import("./../../../src/templates/IndustriesSite.tsx" /* webpackChunkName: "component---src-templates-industries-site-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/LandingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-legal-pages-tsx": () => import("./../../../src/templates/LegalPages.tsx" /* webpackChunkName: "component---src-templates-legal-pages-tsx" */),
  "component---src-templates-orbitvu-page-tsx": () => import("./../../../src/templates/OrbitvuPage.tsx" /* webpackChunkName: "component---src-templates-orbitvu-page-tsx" */),
  "component---src-templates-partnership-page-tsx": () => import("./../../../src/templates/PartnershipPage.tsx" /* webpackChunkName: "component---src-templates-partnership-page-tsx" */),
  "component---src-templates-product-all-category-tsx": () => import("./../../../src/templates/ProductAllCategory.tsx" /* webpackChunkName: "component---src-templates-product-all-category-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/ProductCategory.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/ProductPage.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-software-page-tsx": () => import("./../../../src/templates/SoftwarePage.tsx" /* webpackChunkName: "component---src-templates-software-page-tsx" */),
  "component---src-templates-software-site-tsx": () => import("./../../../src/templates/SoftwareSite.tsx" /* webpackChunkName: "component---src-templates-software-site-tsx" */),
  "component---src-templates-support-page-tsx": () => import("./../../../src/templates/SupportPage.tsx" /* webpackChunkName: "component---src-templates-support-page-tsx" */)
}

